
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[comment-add-component] {
  --comment-area-height: 2.5rem;

  .emoji-picker-wrapper {
    position: absolute;
    bottom: calc(2rem + 60px);;
    z-index: 1;
  }

  .emoji-button {
    width: 35px;

    .btn {
      box-shadow: none;

      i {
        font-size: 1.3rem;
      }
    }
  }

  .reply-content {
    word-break: break-all;
  }

  .new-comment-area {
    color: var(--theme-color);
    overflow: auto;
    outline: none;
    background: rgba(238, 238, 238, 0.6);
    backdrop-filter: blur(10px);
    border-radius: $font-size-sm;
    font-size: $font-size-sm;
    padding-right: 2.5rem !important;
    height: var(--comment-area-height);
    resize: none;

    &::placeholder {
      color: var(--theme-color) !important;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    bottom: .375rem;
    padding: 0;
    border: 0;

    i {
      color: var(--theme-color);
      height: 2.5rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}
