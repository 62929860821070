
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-secondary-component] {

  .nav-link,
  .navbar-toggler {
    &:hover {
      color: rgba($white, 0.75) !important;
    }
  }

  &.sticky {
    position: sticky;
    z-index: 1;
  }

  .nav-item {
    &.active {
      background-color: rgba($white, 0.5);
    }
  }
}
