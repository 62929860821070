// Header height
$header-height-sm: 70px;
$header-height: 70px;

// Colors
$primary: #EA326A;
$secondary: #306DB4;
$tertiary: #F7A71C;
$dark: #000000;
$light: #FFFFFF;
$dark-gray: #2D2D2D;

$unaltrastrada-colors: ("tertiary": $tertiary, "dark-gray": $dark-gray, "rose": $primary);

// Buttons
$btn-border-radius: 1.5rem;
$btn-border-radius-sm: .75rem;
$btn-border-radius-lg: $btn-border-radius;

$btn-padding-x: 3.375rem;
$btn-padding-y: 1rem;
$btn-padding-x-sm: 1.375rem;
$btn-padding-y-sm: .5rem;
$btn-padding-x-lg: $btn-padding-x;
$btn-padding-y-lg: $btn-padding-y;

// Fonts
$font-family-base: "Plus Jakarta Sans", sans-serif;
$font-family-default: $font-family-base;
$enable-responsive-font-sizes: true;

$font-weight-normal: 400;
$font-weight-bold: 700;

$headings-font-weight: 700;

// Other
$user-dashboard-avatar-bg-color: $dark-gray;

@import "~src/stylesheets/fandom_variables";

$colors: map-merge($colors, $unaltrastrada-colors);
$theme-colors: map-merge($theme-colors, $unaltrastrada-colors);

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;