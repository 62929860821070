
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  transition: all .25s;

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }

  .line-clamp-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}
