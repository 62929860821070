
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[ie-banner-component] {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: 0;
  right: 0;
  left: 0;
}
