
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[pwa-banner-component] {
  z-index: calc(#{$zindex-fixed} + 100);
  position: fixed;
  color: white;
  top: 0;

  p { margin: 0; }

  .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
  }
}
