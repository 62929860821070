
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

/* Forced square rappresentation on mobile */
@include media-breakpoint-down(md) {
  //.ar-16by9,
  //.ar-4by3,
  .ar-sm-1by1 {
    padding-bottom: 100%;
  }

  /* Forced no vertical padding on mobile */
  [two-col-component] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

/* IE11 hack */
@include ie {
  div > .row {
    max-height: 1080px;
  }

  .media-column,
  .text-column {
    min-height: inherit;
    max-height: 1080px;
  }
}
