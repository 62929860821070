
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-with-sidebar-component] {

  .nav-link.active {
    color: $primary;
  }

  .spy-sidebar {
    top: var(--main-navbar-height);
  }

  @include media-breakpoint-up(md) {
    .spy-sidebar {
      width: 26.5rem;
      overflow-y: scroll;
      height: calc(100vh - var(--main-navbar-height));
    }
  }

} 
