
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[user-dashboard-component] {
  $title-contrast-size: .5px;
  $title-contrast-color: rgba($white, .75);

  background-color: $user-dashboard-bg-color;

  .edit-background {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: $white;
    opacity: 0;
    background-color: rgba($black, .3);
    transition: opacity .25s ease-in-out;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }

  .dashboard-title {
    color: $black;
    font-size: .75rem;
    line-height: 1.5;
    text-shadow:
      $title-contrast-size 0 0 $title-contrast-color,
      0 $title-contrast-size 0 $title-contrast-color,
      -$title-contrast-size 0 0 $title-contrast-color,
      0 -$title-contrast-size 0 $title-contrast-color;
  }

  .user-dashboard-content {
    height: $user-dashboard-height;
  }

  .z2 {
    z-index: 2;
  }

  .user-dashboard-avatar {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 1.25rem;
    overflow: hidden;
    color: $user-dashboard-avatar-color;
    background-color: $user-dashboard-avatar-bg-color;

    .edit-icon {
      opacity: 0;
      transition: opacity .25s ease-in-out;

      &.has-avatar {
        background-color: rgba($black, .3);
      }
    }

    &:hover {
      .user-icon {
        opacity: 0;
      }

      .edit-icon {
        opacity: 1;
      }
    }
  }

  .user-icon {
    pointer-events: none;
  }
}
