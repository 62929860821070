
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[comment] {
  .comment {
    &__with-parent {
      &::before {
        content: "";
        width: 3px;
        background-color: #F2F2F3;
        border-radius: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        opacity: .5;
      }

      .parent-comment {
        &::before {
          content: "";
          background: $white;
          border-radius: 5px;
          width: 3px;
          position: absolute;
          height: 100%;
          left: -8px;
        }
      }
    }

    &-preview {
      background-color: transparent;
      line-height: 1rem;
      word-break: break-all;

      .fa-reply {
        color: var(--theme-color);
        opacity: .7;
      }

      .reply-badge {
        color: #F2F2F3;
        background: #494B50;
        display: inline-flex;
        font-size: 10px;
      }

      &__name {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        font-size: $font-size-sm;
        line-height: 1rem;
        color: var(--theme-color);
        opacity: 0.75;
      }

      &__text {
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
        font-size: $font-size-sm;
        line-height: 1.1rem;
        color: var(--theme-color);
        word-break: break-word;
      }
    }

    .user-avatar {
      color: var(--theme-color);
    }
  }
}
