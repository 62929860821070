
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-player-component] {
  position: relative;
  background-color: var(--theme-background);
  color: var(--theme-color);
  z-index: 1091;

  ::v-deep {
    .fs-small {
      font-size: $font-size-sm; 
    }

    .fs-xs {
      font-size: $font-size-xs
    }

    .hide-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      
      &::-webkit-scrollbar { display: none; }
    }
  }

  .line-through {
    text-decoration: line-through;
  }

  .rounded {
    border-radius: $player-border-radius !important;

    @media (orientation: portrait) {
      @include media-breakpoint-down(md) {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .left-container {
    &__content, .left-header {
      border-color: #C5C5C959 !important;
    }

    &.player-ratio-16by9 {
      .left-container__content {
        border: none !important;
      }

      .left-header {
        display: none !important;
      }
    }

    @include media-breakpoint-up(lg) {
      &.player-ratio-9by16 {
        .left-container__content {
          background: rgba(0,0,0,.3);
        }
      }
    }

    @include media-breakpoint-down(md) {
      &__content {
        border: none !important;

        .left-header {
          display: none !important;
        }
      }
    }

    ::v-deep {
      [comment-add-component] {
        form {
          width: 100%;
        }
      }
    }
  }

  .right-container {
    position: relative;
    overflow: hidden;

    &.player-ratio-16by9 {
      @media (orientation: landscape) {
        .hide-sidebar {
          i {
            rotate: 180deg;
          }
        }
      }
    }

    .hide-sidebar {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;

      @include media-breakpoint-down(lg) {
        i {
          rotate: -90deg;
        }
      }
    }
  }
}
