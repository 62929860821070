
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.player-controls-interactive {
  .player-button {
    cursor: pointer;
    touch-action: manipulation;
    border: none;
    color: $white;
    margin: 7px 0;
    width: 40px;
    height: 40px;
    background: rgba(170, 170, 170, 0.5);
    mix-blend-mode: normal;
    backdrop-filter: blur(10px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active, &:hover {
      background-color: rgba(238, 238, 238, 0.8);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .badge {
      position: absolute;
      background: $player-red-color;
      left: 0;
      top: 0;
      font-weight: 400;
      font-size: .5rem;
      padding: .4em .4em;
      min-width: 14px;
    }

    i {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 16px;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    }

    &:focus {
      outline: none;
    }
  }
}
