
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[payment-form] {
  .payment-form {
    align-self: center;

    &-content {
      border: 2px solid $black;
    }

    label {
      margin-bottom: 0;
      -webkit-font-smoothing: antialiased;
    }
    
    input {
      line-height: 20px;
      padding: 12px
    }

    .amount-input {
      flex: 1;
    }

    .amount-preselection {
      &-container {
        flex-wrap: wrap;
        gap: 10px;

        .btn-amount-preselection {
          flex: 1;
          min-width: 70px;
        }
      }
    }

    .btn-group {
      gap: 10px;

      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
      }

      .purchase-btn {
        color: #ffffff;
        border-radius: 4px;
        display: block;
        transition: all 0.2s ease;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        width: 100%;
        margin-left: 0;

        &:hover {
          filter: contrast(115%);
        }

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }
      }

      .satispay-button {
        cursor: pointer;
        background-color: #f94c43;
        
        img {
          height: 1.375rem;
          max-height: 1.375rem;
        }
      }

      .paypal-button {
        background: #009cde;
        color: #fff;

        &-label-container {
          height: 1.375rem;
          max-height: 1.375rem;

          img {
            vertical-align: top;
            height: 100%;
            text-align: left;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .btn-amount-preselection {
      padding: 0.75rem 0.75rem;
    }
  }
}
