
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[grid-fixed-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  
  .px-custom {
    padding-left: $fixed-grid-gap;
    padding-right: $fixed-grid-gap;
  }

  .py-custom {
    padding-top: $fixed-grid-gap;
    padding-bottom: $fixed-grid-gap;
  }
}
