
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-accordion-component] {
  background-position: top;
  background-size: 100% auto;

  .icon-container {
    background-color: var(--theme-border-color);
    width: 2rem;
    height: 2rem;
  }

  a.accordion.collapsed .icon-container::before {
    font-family: "Font Awesome 6 Pro";
    content: "\2b";
  }

  a.accordion .icon-container::before {
    font-family: "Font Awesome 6 Pro";
    content: "\f00d";
  }

  .separator {
    height: 1px;
    background-color: var(--gray);
  }
  
}
