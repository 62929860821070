
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-on-demand-event-component] {
  color: var(--theme-color);
  background-color: var(--theme-background);

  .on-demand-event-interactive-sidebar {
    width: 26.5rem;
  }
}
