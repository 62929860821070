
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[footer-component] {

  .separator {
    background-color: $dark;
    height: 1px;
  }

  .footer-description {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
