
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.navbar {
  background: $white !important;
}

[navbar-component] {
  min-height: $header-height-sm;
  transition: all $animation-time-fast linear;

  @include media-breakpoint-up(md) {
    background: transparent;
  }

  .navbar-brand {
    min-height: calc(#{$header-height-sm} - 1rem);
  }

  .navbar-logo {
    height: 2.75rem;
  }

  .sidebar-toggler, .close-icon {
    transition: color .25s;
    
    &:hover {
      color: $gray-600;
    }
  }
}

[navbar-component] .nav-item,
[sidebar] .nav-item {
  font-size: .9rem !important;
  letter-spacing: 1px;
  text-decoration: none !important;

  .nav-item-url {
    color: $black;
    text-decoration: none;
    transition: color .25s;
    
    &.collapsed .fal {
      transform: rotate(180deg);
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    .nav-item-url {
      color: rgba($black, .5);
    }
  }
}

[sidebar-layer] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, .5);
}

[sidebar] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: .875rem;
  max-width: 17.5rem;
  padding-top: 1.5rem;
  overflow-y: auto;

  .user-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .sidebar-content,
  .sidebar-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .sidebar-logo {
    height: 2.75rem;
  }

  .text-normal {
    text-transform: none;
  }

  .collapse-toggle {
    min-width: 25%;
  }

  .border-bottom,
  &.border-right {
    border-color: $gray-200 !important;
  }
}
