
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-alert-component] {
  z-index: 1090;

  .modal-content, .modal-body, .btn {
    border-radius: $player-border-radius !important;
  }

  .close-icon {
    font-size: 1.5rem;
  }
}
