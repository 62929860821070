
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-ivs-component] {
  position: relative;

  ::v-deep i {
    font-size: 1.5rem;
  }
}
