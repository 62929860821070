
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-search-autocomplete-component] {

  button {
    border-radius: 0;
    padding: 0 1.5rem;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .v-autocomplete-input-group {
    width: 100%;
    background-color: $white;
    padding-top: 1px;
    padding-bottom: 1px;

    .v-autocomplete-input {
      border: none;
      box-shadow: none;
      width: 100%;
      outline: none !important;
    }
  }

  .v-autocomplete-list {
    top: 3rem;
    left: 0;
    right: 0;
    outline: 1px solid $gray-300;
    z-index: $zindex-dropdown;

    .v-autocomplete-list-item {
      background-color: $white;
    }
  }
}
