
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.person {
  &.has-bio {
    transition: color .5s, background-color .5s;

    @include hover() {
      .arrow {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.arrow {
  font-size: 1.5rem;
  transform: translate3d(-1.5rem, 0, 0);
  transition: transform .5s;
  width: 3rem;
}

.role {
  font-size: 1rem;
}

.description {
  min-width: 50%;
}

.photo {
  flex: 0 0 auto;
  width: 3.5rem;

  @include media-breakpoint-up(md) {
    width: 5rem;
  }
}
