body {
  padding-top: $header-height;
  @include media-breakpoint-down(sm) {
    padding-top: $header-height-sm;
  }
}

.btn {
  text-transform: uppercase;
  font-weight: bold !important;
  border: none !important;
}

.btn-dark {
  &:hover {
    background-color: rgba($dark, 0.5) !important;
  }
}

.btn-light {
  &:hover {
    background-color: rgba($light, 0.5) !important;
  }
}

.btn-primary {
  @extend .btn-dark;
}

.form-control,
.custom-select {
  border: 1px solid $gray-500 !important;
  border-radius: 12px !important;
}

.custom-file-label {
  border: 1px solid $gray-500 !important;
  border-radius: 12px !important;
  overflow: hidden !important;

  &::after {
    background-color: $dark !important;
    color: $light !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-limiter {
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-height: 1080px) {
  .mh-100vh,  
  .mh-100vh-header {
    height: 1440px !important;
    min-height: auto !important;
  }
}