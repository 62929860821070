
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-chat-ai-sidebar-component] {
  .sidebar {

    .edit, .toggle {
      width: 2rem;
      height: 2rem;
      border-radius: 0.5rem;
      background-color: $dark;
      color: $light;
      cursor: pointer;

      i { font-size: 1.25rem; }
      &.active { transform: rotate(180deg); }
    }

    li {
      background-color: transparent;
      border: 1px solid $light;
      border-radius: 0.5rem;
      padding: 0.5rem;

      .form-control {
        line-height: normal;
        height: auto;
        border-radius: 0.5rem;
      }

      .actions i { opacity: 0; };

      &.active, &:hover {
        background-color: $light !important; 
        .actions i { opacity: 1; };
      }

      a {
        color: $dark !important;
        text-decoration: none !important;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .sidebar {
      position: sticky;
      top: var(--main-navbar-height);
      width: 26.5rem;
      overflow-y: scroll;
      height: calc(100vh - var(--main-navbar-height));

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
