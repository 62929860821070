
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-carousel-component] {
  background-size: 100% auto;

  .carousel-body ::v-deep .container {
    padding-left: 0;
    padding-right: 0;
  }
 
  .flickity {
    &-viewport, &-carousel {
      &:focus {
        outline: none !important;
      }
    }
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
    border: 2px solid var(--theme-color);
    color: var(--theme-color);
    background: transparent;
    width: 56px;
    height: 56px;

    &.next {
      right: -65px;
    }

    &.previous {
      left: -65px;
    }

    @include media-breakpoint-down(xs) {
      border: none;
    }

    &:disabled {
      display: none;
    }
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-page-dots {
    padding-bottom: 3rem;
    position: relative;
    bottom: 0;

    .dot {
      width: 16px;
      height: 16px;
      background: var(--theme-color);
      opacity: 0.35;
      
      &.is-selected {
        opacity: 1;
      }
    }
  }

  .flickity-carousel-item {
    width: 100%;
    overflow: hidden;

    > div {
      position: relative;
    }
  }
}
