
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-search-component] {
  .heading {
    background-color: $gray-200;
  }

  .child:hover {
    .child-title, .child-subtitle {
      color: $gray-400;
    }
  }

  .child-title {
    font-size: 1.25rem;
    transition: color $animation-time-fast linear;
  }

  .child-subtitle {
    transition: color $animation-time-fast linear;
  }

  .custom-input {
    height: 3.75rem;
    padding: 0;
    font-size: 2.25rem;
    text-transform: uppercase;
    color: $dark;
    background-color: transparent;
    border: 0;
    outline-width: 0px;

    &::placeholder {
      color: $gray-400;
    }
  }

}
