
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[heading-media-component] {
  position: relative;

  video {
    object-fit: cover;
  }

  .gradient {
    background: rgba(0,0,0,0.25);
  }
}
