
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-eventbrite-component] {
  background-color: #eee;

  .sold-out {
    background-color: $danger;
    color: #fff;
  }

  .title, .description {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    white-space: nowrap;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  @include media-breakpoint-up(sm) {
    .description {
      -webkit-line-clamp: 2;
    }
  }
}
