
    @import "tenants/tenants-base/unaltrastrada/javascript/src/stylesheets/unaltrastrada_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.modal-content-close {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
  font-size: 2rem;
  color: $white;
  text-decoration: none;
}
